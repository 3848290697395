<template>
  <div>
    <v-row v-if="(breadcrumbs && breadcrumbs.length > 0) || titleInternal.length > 0">
      <!-- BREADCRUMBS --->
      <v-col
        class="py-0 px-8"
        cols="12"
        style="display: flex; align-items: center; justify-content: flex-end"
      >
        <v-breadcrumbs
          divider=">"
          :items="breadcrumbsInternal"
          class="pl-4"
        ></v-breadcrumbs>
      </v-col>
    </v-row>

    <slot name="pageContent">
      <v-card
        outlined
        class="mx-4 mt-4"
      >
        <v-card-title class="pa-0">
          <v-row class="ma-0 px-0 pb-1 accent lighten-4">
            <!-- TITLE -->
            <v-col cols="12">
              <span class="title">{{ title }}</span>
            </v-col>

            <!-- SEARCH FIELD -->
            <v-col
              cols="12"
              v-show="!hideExtension"
              style="height: 55px"
              xs="12"
              sm="12"
              md="6"
              lg="6"
              xl="6"
            >
              <v-text-field
                v-if="!noSearchField"
                dense
                hide-details
                v-model="tableSearch"
                placeholder="Search"
                prepend-icon="mdi-magnify"
                clearable
                autocomplete="off"
              />
            </v-col>

            <!-- BUTTONS -->
            <v-col
              cols="12"
              v-show="!hideExtension"
              class="text-right"
              style="height: 55px"
              xs="12"
              sm="12"
              md="6"
              lg="6"
              xl="6"
            >
              <slot name="actionButtons"></slot>
              <v-btn
                class="ml-1"
                v-if="internalDisplayFAB"
                small
                color="accent"
                title="New"
                @click.native="handleFABClick()"
              >
                <v-icon>mdi-plus</v-icon>
                <span v-show="!$slots.actionButtons">New</span>
              </v-btn>
            </v-col>

            <!-- EXTRA CONTROLS -->
            <v-col
              cols="12"
              class="my-0 py-0"
              v-if="!!$slots.extraControls"
            >
              <slot name="extraControls"></slot>
            </v-col>
          </v-row>
        </v-card-title>

        <v-container fluid>
          <v-row v-if="errorList && errorList.length > 0">
            <v-col
              cols="12"
              sm="12"
            >
              <v-alert type="error">
                <template v-for="item in errorList">{{ item }}</template>
              </v-alert>
            </v-col>
          </v-row>
          <slot name="defaultProcessingContent">
            <v-row v-if="processing">
              <v-col
                cols="12"
                sm="1"
                class="ml-4"
              >
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-container>Processing...</v-container>
              </v-col>
            </v-row>
          </slot>

          <v-row v-if="
              lastRefresh !== null &&
              lastRefresh !== undefined &&
              lastRefresh !== ''
            ">
            <v-col
              cols="12"
              class="text-right py-0"
            >
              <span class="caption">Last refresh: {{ lastRefresh }}</span>
            </v-col>
          </v-row>
          <slot name="defaultDataTable">
            <v-row v-if="dataTableConfig !== undefined && dataTableConfig !== null">
              <v-col
                cols="12"
                sm="12"
              >
                <v-data-table
                  ref="dataTable"
                  v-if="!processing"
                  :headers="headers"
                  :items="dataTableItems"
                  :options.sync="tableOptions"
                  :footer-props="{
                    'items-per-page-options': [5, 10, 20, -1]
                  }"
                  :search="tableSearch"
                  :dense="!(denseTable === false)"
                  :no-data-text="( noDataText !== null ) ? noDataText : $vuetify.noDataText"
                  :mobile-breakpoint="700"
                >
                  <template v-slot:item.recordActions="{ item }">
                    <v-btn
                      v-if="optionResolved(editOption, item)"
                      color="primary"
                      xxxclass="mr-2"
                      @click.native="handleEditClick(item)"
                      small
                      class="mt-1 mb-1 mr-2"
                    >
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="optionResolved(printOption, item)"
                      color="accent"
                      xxxclass="mr-2"
                      @click.native="handlePrintClick(item)"
                      class="mt-1 mb-1 mr-2"
                      small
                    >
                      <v-icon>mdi-printer</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="optionResolved(deleteOption, item)"
                      color="error"
                      @click.native="handleDeleteClick(item)"
                      class="mt-1 mb-1 mr-2"
                      small
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </slot>
        </v-container>
      </v-card>
    </slot>
  </div>
</template>

<script>
export default {
  props: [
    "breadcrumbs",
    "dataTableConfig",
    "title",
    "errorList",
    "processing",
    "displayFAB",
    "editOption",
    "deleteOption",
    "printOption",
    "denseTable",
    "noSearchField",
    "lastRefresh",
    "hideExtension",
    "noDataText",
  ],

  mounted ()
  {
    this.calcExtensionHeight();

    this.titleInternal = this.title || "";

    this.breadcrumbsInternal = this.breadcrumbs || [];
    if (this.dataTableConfig)
    {
      this.extractDataTableConfig();
    }
    if (this.displayFAB !== null && this.displayFAB === false)
    {
      this.internalDisplayFAB = false;
    }
  },

  updated ()
  {
    this.calcExtensionHeight();
  },

  methods: {
    optionResolved (option, item)
    {
      if (option === undefined || option === null)
      {
        return false;
      }

      if (typeof option === "boolean")
      {
        return option;
      }

      if (typeof option === "function")
      {
        return option(item);
      }

      return false;
    },
    calcExtensionHeight ()
    {
      var aExtensions = document.getElementsByClassName(
        "pageContentToolbarExtension"
      );
      var oExtesion = aExtensions.namedItem(this.templateId);

      this.extensionHeight = oExtesion && !this.hideExtension ? oExtesion.offsetHeight + "px" : "0px";
    },

    handleFABClick ()
    {
      this.$emit("defaultFABClick");
    },

    handleEditClick (item)
    {
      this.$emit("tableEditAction", item);
    },

    handlePrintClick (item)
    {
      this.$emit("tablePrintAction", item);
    },

    async handleDeleteClick (item)
    {
      let res = await this.$root.$confirm(
        "Are you sure that you wish to delete this record?",
        { title: "Delete?", color: "error", positiveText: "Delete" }
      );
      if (res)
      {
        this.$emit("tableDeleteAction", item);
      }
    },

    extractDataTableConfig ()
    {
      if (this.dataTableConfig)
      {
        this.headers =
          [
            ...this.dataTableConfig.headers,
            { text: "", value: "recordActions" },
          ] || [];
        this.dataTableItems = this.dataTableConfig.items || [];
      }
    },
  },
  watch: {
    dataTableItems ()
    {
    },

    dataTableConfig ()
    {
      this.extractDataTableConfig();
    },

    hideExtension ()
    {
      this.calcExtensionHeight();
    },
  },

  data: () =>
  {
    return {
      templateId: "ID" + Math.floor(Math.random() * 100000),
      extensionHeight: "0px",
      headers: [],
      dataTableItems: [],
      tableOptions: {
        itemsPerPage: 10,
        page: 1,
      },
      titleInternal: "",
      breadcrumbsInternal: [],
      errorListInternal: [],
      processingInternal: false,
      internalDisplayFAB: true,
      tableSearch: "",
    };
  },

  computed: {},
};
</script>

<style>
</style>
<template>
  <div>
    <page-template
      id="mainList"
      :breadcrumbs="breadcrumbs"
      :title="pageTitle"
      :errorList="errorList"
      :processing="processing"
      :dataTableConfig="{
        headers: [
          { text: 'Organisational Unit', value: 'OrgUnit' },
          { text: 'Unit Type', value: 'UnitType' },
          { text: 'Reg Number', value: 'RegNumber' },
          { text: 'Tare KG', value: 'tareWeightKG' },
          { text: 'Status', value: 'status' },
        ],
        items: this.fleetList,
      }"
      :displayFAB="false"
      :editOption="false"
      :denseTable="true"
      @xxxdefaultFABClick="handleFABClick"
      @tableEditAction="handleTableEditAction"
    >
      <template slot="defaultCardTitle">Fleet List</template>
    </page-template>

    <!-- ADD/EDIT DIALOG -->
    <v-dialog persistent v-model="showAddEditForm">
      <v-card style="height: 100%">
        <v-card-title class="pa-0">
          <v-toolbar
            dark
            color="primary"
            :extension-height="formErrorList.length * 48 + 'px'"
          >
            <v-toolbar-title>Fleet Unit</v-toolbar-title>
            <template v-slot:extension>
              <v-row no-gutters>
                <v-col cols="12" class="py-4">
                  <error-messages :errorList="formErrorList"></error-messages>
                </v-col>
              </v-row>
            </template>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="px-0 pb-0" style="height: 100%">
          <v-form ref="fleetForm">
            <v-container fluid class="px-0 pb-0">
              <v-tabs v-model="activeTab">
                <v-tab href="#tab-details">Details</v-tab>
                <v-tab href="#tab-drivers" v-show="addEditRecordValues._id"
                  >Associated Drivers</v-tab
                >
                <v-tab href="#tab-history" v-show="addEditRecordValues._id"
                  >History</v-tab
                >
                <v-tab
                  href="#tab-nonavailability"
                  v-show="addEditRecordValues._id"
                  >Non-Availability</v-tab
                >
                <v-tab href="#tab-documents" v-show="addEditRecordValues._id"
                  >Documents</v-tab
                >
              </v-tabs>
              <v-tabs-items v-model="activeTab">
                <v-tab-item
                  class="px-8 pb-4"
                  value="tab-details"
                  :style="{
                    height: dialogContentHeight,
                    overflowY: 'auto',
                    overflowX: 'clip',
                  }"
                >
                  <v-row>
                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                      <v-row class="pa-0">
                        <v-col cols="12">
                          <!-- <v-autocomplete
                            type="text"
                            label="Organisational Unit"
                            :items="orgUnitList"
                            :rules="[
                              (v) => !!v || 'Organisational Unit is required',
                            ]"
                            item-value="Name"
                            item-text="Name"
                            v-model="addEditRecordValues.OrgUnit"
                          /> -->

                          <v-text-field
                            placeholder="Organisational Unit"
                            label="Organisational Unit"
                            :rules="[
                              (v) => !!v || 'Organisational Unit is required',
                            ]"
                            v-model="addEditRecordValues.OrgUnit"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            type="text"
                            label="Unit Type"
                            :items="unitTypeList"
                            :rules="[(v) => !!v || 'Unit Type is required']"
                            item-value="value"
                            item-text="value"
                            v-model="addEditRecordValues.UnitType"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            type="text"
                            label="Reg Number"
                            :rules="[(v) => !!v || 'Reg Number is required']"
                            v-model="addEditRecordValues.RegNumber"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            type="text"
                            label="Make"
                            v-model="addEditRecordValues.Make"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            type="text"
                            label="Model"
                            v-model="addEditRecordValues.Model"
                          />
                        </v-col>

                        <v-col cols="12">
                          <v-select
                            label="Twin Pot"
                            placeholder="Twin Pot"
                            :items="[
                              { text: 'No', value: false },
                              { text: 'Yes', value: true },
                            ]"
                            v-model="addEditRecordValues.twinPot"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                      <v-row class="pa-0">
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            label="Max Volume (litres)"
                            v-model="addEditRecordValues.MaxVolumeLitres"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            label="Max Weight (KG)"
                            v-model="addEditRecordValues.MaxWeightKG"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            label="Tare Weight (KG)"
                            v-model="addEditRecordValues.tareWeightKG"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            label="Bulk Load"
                            placeholder="Bulk Load"
                            :items="[
                              { text: 'No', value: false },
                              { text: 'Yes', value: true },
                            ]"
                            v-model="addEditRecordValues.bulkLoad"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            label="Notes"
                            v-model="addEditRecordValues.Notes"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>

                <v-tab-item
                  value="tab-drivers"
                  v-if="addEditRecordValues._id"
                  :style="{
                    height: dialogContentHeight,
                    overflowY: 'auto',
                    overflowX: 'clip',
                  }"
                >
                  <page-template
                    title="Associated Drivers"
                    :editOption="false"
                    :deleteOption="true"
                    :dataTableConfig="{
                      headers: [
                        { text: 'Forename', value: 'Forename' },
                        { text: 'Surname', value: 'Surname' },
                        { text: 'Telno', value: 'Telno' },
                        { text: 'Email', value: 'Email' },
                      ],
                      items: addEditRecordValues.defaultDrivers,
                    }"
                    @defaultFABClick="addDriver"
                    @tableDeleteAction="deleteDriverRecord"
                  >
                  </page-template>

                  <!-- <driver-select
                    :multiSelect="true"
                    :paramShow="showDriverDialog"
                    :paramPreselected="addEditRecordValues.defaultDrivers"
                    @driverSelectorSave="saveNewDriver"
                    @cancel="cancelNewDriver"
                  ></driver-select> -->
                </v-tab-item>

                <v-tab-item
                  value="tab-history"
                  v-if="addEditRecordValues._id"
                  :style="{
                    height: dialogContentHeight,
                    overflowY: 'auto',
                    overflowX: 'clip',
                  }"
                >
                  <page-template
                    id="historyList"
                    title="Fleet Unit History List"
                    :editOption="true"
                    :dataTableConfig="{
                      headers: [
                        { text: 'Date', value: 'britishDate' },
                        { text: 'Type', value: 'type' },
                        { text: 'Garage', value: 'garageDetails.name' },
                        { text: 'Cost', value: 'cost' },
                        { text: 'Notes', value: 'notes' },
                      ],
                      items: historyListItems,
                    }"
                    @defaultFABClick="handleNewHistoryClick"
                    @tableEditAction="handleHistoryEdit"
                  >
                  </page-template>
                </v-tab-item>

                <v-tab-item
                  value="tab-nonavailability"
                  v-if="addEditRecordValues._id"
                  :style="{
                    height: dialogContentHeight,
                    overflowY: 'auto',
                    overflowX: 'clip',
                  }"
                >
                  <page-template
                    id="nonAvailList"
                    title="Non-Availability List"
                    :editOption="true"
                    :deleteOption="true"
                    :dataTableConfig="{
                      headers: [
                        { text: 'Type', value: 'type' },
                        {
                          text: 'From Date / Time',
                          value: 'britishFromDateTime',
                        },
                        { text: 'To Date / Time', value: 'britishToDateTime' },
                        { text: 'Notes', value: 'notes' },
                      ],
                      items: nonAvailListComputed,
                    }"
                    @defaultFABClick="handleNewNonAvailEntry"
                    @tableEditAction="handleEditNonAvailEntry"
                    @tableDeleteAction="handleDeleteNonAvailEntry"
                  >
                  </page-template>
                </v-tab-item>

                <v-tab-item
                  value="tab-documents"
                  v-if="addEditRecordValues._id"
                  :style="{
                    height: dialogContentHeight,
                    overflowY: 'auto',
                    overflowX: 'clip',
                  }"
                >
                  <document-manager
                    :entity="addEditRecordValues"
                    entityType="Fleet Unit"
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions class="pa-0">
          <v-toolbar>
            <v-spacer></v-spacer>
            <v-btn text @click.native="addEditFormCancel">Cancel</v-btn>
            <!-- <v-btn dark color="primary" @click.native="addEditFormSave"
              >Save</v-btn
            > -->
          </v-toolbar>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- HISTORY DIALOG -->
    <v-dialog persistent v-model="showFleetHistoryAddEdit">
      <v-card style="height: 100%">
        <v-card-title class="pa-0">
          <v-toolbar
            dark
            color="primary"
            :extension-height="historyFormErrorList.length * 48 + 'px'"
          >
            <v-toolbar-title>History Entry</v-toolbar-title>
            <template v-slot:extension>
              <v-row no-gutters>
                <v-col cols="12" class="py-4">
                  <error-messages
                    :errorList="historyFormErrorList"
                  ></error-messages>
                </v-col>
              </v-row>
            </template>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="px-0 pb-0" style="height: 100%">
          <v-form ref="historyForm">
            <v-container fluid class="px-0 pb-0">
              <v-tabs v-model="historyTab">
                <v-tab href="#tab-details">Details</v-tab>
                <v-tab href="#tab-historyitems">Items</v-tab>
              </v-tabs>
              <v-tabs-items v-model="historyTab">
                <v-tab-item
                  class="px-8 pb-4"
                  value="tab-details"
                  :style="{
                    height: dialogHeightHistory,
                    overflowY: 'auto',
                    overflowX: 'clip',
                  }"
                >
                  <v-row>
                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                      <v-row class="pa-0">
                        <v-col cols="12">
                          <v-text-field
                            type="date"
                            label="Date"
                            :rules="[(v) => !!v || 'Date is required']"
                            v-model="fleetHistoryRecord.date"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            label="History Type"
                            :items="historyTypeList"
                            :rules="[(v) => !!v || 'History Type is required']"
                            v-model="fleetHistoryRecord.type"
                            item-text="value"
                            item-value="value"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            label="Cost"
                            v-model="fleetHistoryRecord.cost"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            label="Notes"
                            v-model="fleetHistoryRecord.notes"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                      <v-row class="pa-0">
                        <v-col cols="12">
                          <v-text-field
                            type="text"
                            label="Garage Name"
                            :rules="[(v) => !!v || 'Garage name is required']"
                            v-model="fleetHistoryRecord.garageDetails.name"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            type="text"
                            label="Garage Post Code"
                            :rules="[
                              (v) => !!v || 'Garage Post Code is required',
                            ]"
                            v-model="fleetHistoryRecord.garageDetails.postCode"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            type="text"
                            label="Garage Telno"
                            v-model="fleetHistoryRecord.garageDetails.telno"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            type="email"
                            label="Garage Email"
                            v-model="fleetHistoryRecord.garageDetails.email"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            type="text"
                            label="Garage Contact Name"
                            v-model="
                              fleetHistoryRecord.garageDetails.contactName
                            "
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>

                <v-tab-item
                  v-if="addEditRecordValues._id"
                  value="tab-historyitems"
                  :style="{
                    height: dialogHeightHistory,
                    overflowY: 'auto',
                    overflowX: 'clip',
                  }"
                >
                  <page-template
                    id="historyItemList"
                    title="Items List"
                    :editOption="true"
                    :deleteOption="true"
                    :dataTableConfig="{
                      headers: [
                        { text: 'Code', value: 'code' },
                        { text: 'Description', value: 'description' },
                      ],
                      items: fleetHistoryRecord.items,
                    }"
                    @defaultFABClick="handleNewHistoryItemClick"
                    @tableEditAction="historyItemEdit"
                    @tableDeleteAction="historyItemDelete"
                  >
                  </page-template>
                </v-tab-item>
              </v-tabs-items>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions class="pa-0">
          <v-toolbar>
            <v-spacer></v-spacer>
            <v-btn text @click.native="historyFormCancel">Cancel</v-btn>
            <v-btn dark color="primary" @click.native="historyFormSave"
              >Save</v-btn
            >
          </v-toolbar>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- NON-AVAILABILITY DIALOG -->
    <v-dialog persistent v-model="showNonAvailForm">
      <v-card style="height: 100%">
        <v-card-title class="pa-0">
          <v-toolbar
            dark
            color="primary"
            :extension-height="nonAvailFormErrors.length * 48 + 'px'"
          >
            <v-toolbar-title>Non Availability Entry</v-toolbar-title>
            <template v-slot:extension>
              <v-row no-gutters>
                <v-col cols="12" class="py-4">
                  <error-messages
                    :errorList="nonAvailFormErrors"
                  ></error-messages>
                </v-col>
              </v-row>
            </template>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="px-0 pb-0" style="height: 100%">
          <v-form ref="nonAvailForm">
            <v-container fluid class="px-0 pb-0">
              <v-tabs v-model="nonAvailTab">
                <v-tab href="#tab-details">Details</v-tab>
              </v-tabs>
              <v-tabs-items v-model="nonAvailTab">
                <v-tab-item
                  class="px-8 pb-4"
                  value="tab-details"
                  :style="{
                    height: dialogHeightNonAvail,
                    overflowY: 'auto',
                    overflowX: 'clip',
                  }"
                >
                  <v-row>
                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                      <v-row class="pa-0">
                        <v-col cols="12">
                          <v-select
                            label="Type"
                            :items="nonAvailTypeList"
                            :rules="[(v) => !!v || 'Type is required']"
                            v-model="nonAvailRecord.type"
                            item-text="value"
                            item-value="value"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            type="date"
                            label="From Date"
                            :rules="[(v) => !!v || 'From Date is required']"
                            v-model="nonAvailRecord.dateFrom"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            type="time"
                            label="From  Time"
                            :rules="[(v) => !!v || 'From  Time is required']"
                            v-model="nonAvailRecord.timeFrom"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            type="date"
                            label="To Date"
                            :rules="[(v) => !!v || 'To Date is required']"
                            v-model="nonAvailRecord.dateTo"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            type="time"
                            label="To  Time"
                            :rules="[(v) => !!v || 'To  Time is required']"
                            v-model="nonAvailRecord.timeTo"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                      <v-row class="pa-0">
                        <v-col cols="12">
                          <v-textarea
                            label="Notes"
                            v-model="nonAvailRecord.notes"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions class="pa-0">
          <v-toolbar>
            <v-spacer></v-spacer>
            <v-btn text @click.native="nonAvailCancel">Cancel</v-btn>
            <v-btn dark color="primary" @click.native="nonAvailSave"
              >Save</v-btn
            >
          </v-toolbar>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- HISTORY ITEM DIALOG -->
    <v-dialog persistent v-model="showHistoryItemDialog" max-width="900px">
      <v-card style="height: 100%">
        <v-card-title class="pa-0">
          <v-toolbar
            dark
            color="primary"
            :extension-height="historyItemErrorList.length * 48 + 'px'"
          >
            <v-toolbar-title>History Item</v-toolbar-title>
            <template v-slot:extension>
              <v-row no-gutters>
                <v-col cols="12" class="py-4">
                  <error-messages
                    :errorList="historyItemErrorList"
                  ></error-messages>
                </v-col>
              </v-row>
            </template>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="px-0 pb-0" style="height: 100%">
          <v-form ref="historyItemForm">
            <v-container fluid class="px-0 pb-0">
              <v-tabs v-model="historyItemTab">
                <v-tab href="#tab-details">Details</v-tab>
              </v-tabs>
              <v-tabs-items v-model="historyItemTab">
                <v-tab-item
                  class="px-8 pb-4"
                  value="tab-details"
                  :style="{
                    height: dialogHeightHistoryItem,
                    overflowY: 'auto',
                    overflowX: 'clip',
                  }"
                >
                  <v-row>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                      <v-row class="pa-0">
                        <v-col cols="12">
                          <v-text-field
                            type="text"
                            label="Item Code"
                            :rules="[(v) => !!v || 'Item Code is required']"
                            v-model="fleetHistoryItemRecord.code"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            type="text"
                            label="Item Description"
                            :rules="[
                              (v) => !!v || 'Item Description is required',
                            ]"
                            v-model="fleetHistoryItemRecord.description"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions class="pa-0">
          <v-toolbar>
            <v-spacer></v-spacer>
            <v-btn text @click.native="historyItemCancel">Cancel</v-btn>
            <v-btn dark color="primary" @click.native="historyItemSave"
              >Save</v-btn
            >
          </v-toolbar>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SelectFunctions from "@/select/SelectFunctions";

import ErrorMessages from "@/components/ErrorMessages";
import PageTemplate from "../components/PageTemplate.vue";

import ObjectID from "bson-objectid";
import _ from "lodash";
import dayjs from "dayjs";

export default {
  components: { PageTemplate, ErrorMessages },

  async mounted() {
    this.loadOptions();
    this.loadFleetList();
  },

  methods: {
    loadOptions() {
      this.getOrgUnits();
      this.loadUnitTypes();

      // HISTORY TAB
      this.getHistoryTypeList();

      // NON-AVAILABILITY DIALOG
      this.getNonAvailTypeList();
    },

    async getOrgUnits() {
      let response = await SelectFunctions.fetch(
        process.env.VUE_APP_BACKEND_ENDPOINT + `orgunits`,
        { credentials: "include" }
      );

      this.orgUnitList = await response.json();
    },

    async loadUnitTypes() {
      this.unitTypeList = await SelectFunctions.getPopups("FLEET_UNIT_TYPE");
    },

    async loadFleetList() {
      this.processing = true;

      let response = await SelectFunctions.fetch(
        process.env.VUE_APP_BACKEND_ENDPOINT + "transport/fleet",
        { credentials: "include" }
      );

      if (response.ok) {
        this.fleetList = await response.json();
      } else {
        // this.errorList = [];
        this.errorList.push(
          "There was a problem loading the fleet list from the server"
        );
      }
      this.processing = false;
    },

    async getHistoryTypeList() {
      this.historyTypeList = await SelectFunctions.getPopups(
        "FLEET_HISTORY_TYPE"
      );
    },

    async getNonAvailTypeList() {
      this.nonAvailTypeList = await SelectFunctions.getPopups(
        "FLEET_NONAVAILTYPES"
      );
    },

    /** ADD/EDIT DIALOG FUNCTIONS **/

    getNewFleetUnit() {
      return Object.assign(
        {},
        {
          OrgUnit: "",
          UnitType: "",
          RegNumber: "",
          Make: "",
          Model: "",
          MaxVolumeLitres: 0.0,
          MaxWeightKG: 0.0,
          tareWeightKG: 0.0,
          Notes: "",
        }
      );
    },

    handleFABClick() {
      this.formErrorList = [];
      this.addEditRecordValues = this.getNewFleetUnit();
      this.showAddEditForm = true;
    },

    handleTableEditAction(item) {
      this.formErrorList = [];
      this.addEditRecordValues = item;
      this.showAddEditForm = true;

      this.historyListItems = item.history.map((item) => {
        item.date = dayjs(item.date).format("YYYY-MM-DD");
        item.britishDate = dayjs(item.date).format("DD/MM/YYYY");
        return item;
      });
      this.nonAvailList = item.nonAvailability;
    },

    async addEditFormSave() {
      if (!this.$refs["fleetForm"].validate()) {
        this.$notify({
          group: "global",
          type: "error",
          title: "Validation Error",
          text: `Please ensure that you complete all required fields on the form as indicated`,
        });
      } else {
        this.formErrorList = [];
        var bSuccess = await this.onSaveFleet(this.addEditRecordValues);
        if (bSuccess) {
          this.$refs["fleetForm"].resetValidation();
          this.showAddEditForm = false;
          this.loadFleetList();
        }
      }
    },

    async onSaveFleet(formData) {
      let response = await SelectFunctions.saveFormData(
        formData,
        process.env.VUE_APP_BACKEND_ENDPOINT + "transport/fleet"
      );

      if (response && response.ok) {
        this.$notify({
          group: "global",
          type: "success",
          title: "Record Saved Successfully",
          text: `Record for Fleet Unit '${formData.RegNumber}' saved successfully`,
        });

        return true;
      } else {
        this.processError(response);
        return false;
      }
    },

    async processError(response) {
      this.formErrorList.push(
        "There was a problem saving the Fleet Unit Record"
      );
      let errorDetails = null;

      try {
        errorDetails = await response.json();
      } catch (e) {
        errorDetails = null;
      }

      if (errorDetails && errorDetails.code) {
        if (errorDetails.code == "11000") {
          this.formErrorList.push("Registration number already exists");
        }
      }
    },

    async addEditFormCancel() {
      let res = await this.$root.$confirm(
        "You have changed values on this form - discard changes?",
        {
          title: "Discard Changes?",
          color: "warning",
          positiveText: "Yes - Discard",
        }
      );
      if (res) {
        this.$refs["fleetForm"].resetValidation();
        this.showAddEditForm = false;
        this.loadFleetList();
      }
    },

    /** HISTORY DIALOG **/

    getNewHistory() {
      return Object.assign(
        {},
        {
          date: "",
          type: "",
          garageDetails: {
            name: "",
            postCode: "",
            telno: "",
            email: "",
            contactName: "",
          },
          cost: 0.0,
          notes: "",
        }
      );
    },

    async handleNewHistoryClick() {
      this.historyFormErrorList = [];
      this.fleetHistoryRecord = this.getNewHistory();
      this.showFleetHistoryAddEdit = true;
    },

    handleHistoryEdit(item) {
      this.historyFormErrorList = [];
      this.fleetHistoryRecord = item;
      this.showFleetHistoryAddEdit = true;
    },

    async historyFormSave() {
      if (!this.$refs["historyForm"].validate()) {
        this.$notify({
          group: "global",
          type: "error",
          title: "Validation Error",
          text: `Please ensure that you complete all required fields on the form as indicated`,
        });
      } else {
        this.historyFormErrorList = [];
        var bSuccess = await this.onSaveHistory(this.fleetHistoryRecord);
        if (bSuccess) {
          this.$refs["historyForm"].resetValidation();
          this.showFleetHistoryAddEdit = false;
        }
      }
    },

    async onSaveHistory(formData) {
      let response = await SelectFunctions.saveFormData(
        formData,
        process.env.VUE_APP_BACKEND_ENDPOINT +
          `transport/fleet/${this.addEditRecordValues._id}/history`
      );

      if (response && response.ok) {
        this.$notify({
          group: "global",
          type: "success",
          title: "Record Saved Successfully",
          text: `Record for History Entry saved successfully`,
        });
        // retrieve the new history record so that we can update the history list:
        response = await SelectFunctions.fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT +
            `transport/fleet/${this.addEditRecordValues._id}`,
          { credentials: "include" }
        );

        if (response && response.ok) {
          // got the record, update the history list
          let fleetUnitRecord = await response.json();
          this.historyListItems = fleetUnitRecord.history;
          // ensure that the dates are formatted correctly
          this.historyListItems = this.historyListItems.map((item) => {
            item.date = this.$moment(item.date).format("Y-MM-DD");
            item.britishDate = this.$moment(item.date).format("DD/MM/YYYY");
            return item;
          });

          this.historyFormForceChangeStatus = false;
        }

        return true;
      } else {
        this.historyFormErrorList.push(
          "There was problem updating the history record"
        );

        return false;
      }
    },

    async historyFormCancel() {
      let res = await this.$root.$confirm(
        "You have changed values on this form - discard changes?",
        {
          title: "Discard Changes?",
          color: "warning",
          positiveText: "Yes - Discard",
        }
      );
      if (res) {
        this.$refs["historyForm"].resetValidation();
        this.showFleetHistoryAddEdit = false;
      }
    },

    /** HISTORY ITEM DIALOG **/

    getNewHistoryItem() {
      return Object.assign(
        {},
        {
          code: "",
          description: "",
        }
      );
    },

    handleNewHistoryItemClick() {
      this.historyItemErrorList = [];
      this.fleetHistoryItemRecord = this.getNewHistoryItem();
      this.showHistoryItemDialog = true;
    },

    historyItemEdit(item) {
      this.historyItemErrorList = [];
      this.fleetHistoryItemRecord = item;
      this.showHistoryItemDialog = true;
    },

    historyItemDelete(item) {
      let index = _.findIndex(this.fleetHistoryRecord.items, { _id: item._id });
      this.fleetHistoryRecord.items.splice(index, 1);
      this.historyFormForceChangeStatus = true;
    },

    async historyItemSave() {
      if (!this.$refs["historyItemForm"].validate()) {
        this.$notify({
          group: "global",
          type: "error",
          title: "Validation Error",
          text: `Please ensure that you complete all required fields on the form as indicated`,
        });
      } else {
        this.historyItemErrorList = [];
        var bSuccess = await this.onSaveHistoryItem(
          this.fleetHistoryItemRecord
        );
        if (bSuccess) {
          this.$refs["historyItemForm"].resetValidation();
          this.showHistoryItemDialog = false;
        }
      }
    },

    onSaveHistoryItem(formData) {
      // okay, this is going against the currently edited / inserted history entry as line item.
      // we therefore need to determine if we are updating / editing the item in the array.

      if (!formData._id) {
        // in add mode
        if (!this.fleetHistoryRecord.items) {
          this.fleetHistoryRecord.items = [];
        }
        this.fleetHistoryRecord.items.push({
          _id: ObjectID(),
          code: formData.code,
          description: formData.description,
        });
      } else {
        // in edit mode
        let index = _.findIndex(this.fleetHistoryRecord.items, {
          _id: formData._id,
        });
        this.fleetHistoryRecord.items.splice(index, 1, formData);
      }

      return true;
    },

    historyItemCancel() {
      this.showHistoryItemDialog = false;
    },

    /** NON-AVAIL DIALOG **/
    getNewNonAvail() {
      return Object.assign(
        {},
        {
          type: "",
          dateFrom: "",
          timeFrom: null,
          dateTo: "",
          timeTo: null,
          notes: "",
        }
      );
    },

    async handleNewNonAvailEntry() {
      this.nonAvailFormErrors = [];
      this.nonAvailRecord = this.getNewNonAvail();
      this.showNonAvailForm = true;
    },

    async handleEditNonAvailEntry(item) {
      this.nonAvailFormErrors = [];
      this.nonAvailRecord = item;
      this.showNonAvailForm = true;
    },

    async handleDeleteNonAvailEntry(item) {
      let response = await SelectFunctions.fetch(
        process.env.VUE_APP_BACKEND_ENDPOINT +
          `transport/fleet/${this.addEditRecordValues._id}/nonavailability`,
        {
          credentials: "include",
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(item),
        }
      );

      if (response && response.ok) {
      } else {
        this.$notify({
          group: "global",
          type: "error",
          title: "Problem deleting record",
          text: `There was a problem deleting this record`,
        });
      }

      // retrieve the  fleet record so that we can update the non avail list:
      response = await SelectFunctions.fetch(
        process.env.VUE_APP_BACKEND_ENDPOINT +
          `transport/fleet/${this.addEditRecordValues._id}`,
        { credentials: "include" }
      );

      if (response && response.ok) {
        // got the record, update the history list
        let fleetUnitRecord = await response.json();
        this.nonAvailList = fleetUnitRecord.nonAvailability;
      }
    },

    async nonAvailSave() {
      if (!this.$refs["nonAvailForm"].validate()) {
        this.$notify({
          group: "global",
          type: "error",
          title: "Validation Error",
          text: `Please ensure that you complete all required fields on the form as indicated`,
        });
      } else {
        this.nonAvailFormErrors = [];
        var bSuccess = await this.onSaveNonAvail(this.nonAvailRecord);
        if (bSuccess) {
          this.$refs["nonAvailForm"].resetValidation();
          this.showNonAvailForm = false;
        }
      }
    },

    async onSaveNonAvail(formData) {
      formData.datetimeFrom =
        dayjs(formData.dateFrom).format("YYYY-MM-DD") +
        "T" +
        formData.timeFrom +
        ":00";
      formData.datetimeTo =
        dayjs(formData.dateTo).format("YYYY-MM-DD") +
        "T" +
        formData.timeTo +
        ":00";

      let response = await SelectFunctions.saveFormData(
        formData,
        process.env.VUE_APP_BACKEND_ENDPOINT +
          `transport/fleet/${this.addEditRecordValues._id}/nonavailability`
      );

      if (response && response.ok) {
        this.$notify({
          group: "global",
          type: "success",
          title: "Record Saved Successfully",
          text: `Record for Non-Availability Entry saved successfully`,
        });

        // retrieve the  fleet record so that we can update the non avail list:
        var response2 = await SelectFunctions.fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT +
            `transport/fleet/${this.addEditRecordValues._id}`,
          { credentials: "include" }
        );

        if (response2 && response2.ok) {
          // got the record, update the history list
          let fleetUnitRecord = await response2.json();
          this.nonAvailList = fleetUnitRecord.nonAvailability;
        }

        return true;
      } else {
        this.nonAvailFormErrors.push(
          "There was a problem updating the non-availability entry"
        );

        return false;
      }
    },

    async nonAvailCancel() {
      let res = await this.$root.$confirm(
        "You have changed values on this form - discard changes?",
        {
          title: "Discard Changes?",
          color: "warning",
          positiveText: "Yes - Discard",
        }
      );
      if (res) {
        this.$refs["nonAvailForm"].resetValidation();
        this.showNonAvailForm = false;
      }
    },

    /** DRIVER OPERATIONS **/

    deleteDriverRecord(item) {
      var iRemovableIndex = this.addEditRecordValues.defaultDrivers.findIndex(
        (o) => o._id === item._id
      );
      if (iRemovableIndex > -1) {
        this.addEditRecordValues.defaultDrivers.splice(iRemovableIndex, 1);
      }
    },

    addDriver() {
      this.showDriverDialog = true;
    },

    cancelNewDriver() {
      this.showDriverDialog = false;
    },

    saveNewDriver(aDrivers) {
      this.addEditRecordValues.defaultDrivers = aDrivers;

      this.showDriverDialog = false;
    },
  },

  computed: {
    nonAvailListComputed() {
      let returnValue = [];
      if (this.nonAvailList) {
        returnValue = this.nonAvailList.map((item) => {
          item.britishFromDateTime = this.$moment(item.datetimeFrom).format(
            "DD/MM/YYYY HH:mm"
          );
          item.britishToDateTime = this.$moment(item.datetimeTo).format(
            "DD/MM/YYYY HH:mm"
          );

          item.dateFrom = this.$moment(item.datetimeFrom).format("YYYY-MM-DD");
          item.dateTo = this.$moment(item.datetimeTo).format("YYYY-MM-DD");

          item.timeFrom = this.$moment(item.datetimeFrom).format("HH:mm");
          item.timeTo = this.$moment(item.datetimeTo).format("HH:mm");

          return item;
        });
      }

      return returnValue;
    },
  },

  data: () => {
    return {
      dialogContentHeight: "60vh",
      activeTab: "tab-details",

      pageTitle: "Fleet Management",
      errorList: [],
      processing: false,
      fleetList: [],

      breadcrumbs: [
        {
          text: "Home",
          icon: "mdi-home",
          href: "/",
        },
        {
          text: "Fleet Management",
          icon: "",
          href: "#",
          disabled: true,
        },
      ],

      showAddEditForm: false,
      addEditRecordValues: {},

      formErrorList: [],

      // add/edit details
      orgUnitList: [],

      unitTypeList: [],

      // add / edit history form properties
      showFleetHistoryAddEdit: false,
      dialogHeightHistory: "50vh",
      historyTab: "tab-details",
      fleetHistoryRecord: {
        date: "",
        type: "",
        garageDetails: {
          name: "",
          postCode: "",
          telno: "",
          email: "",
          contactName: "",
        },
        cost: 0.0,
        notes: "",
      },
      historyFormErrorList: [],
      historyListItems: [],
      historyFormForceChangeStatus: false,

      historyTypeList: [],

      // history item add
      historyItemErrorList: [],
      showHistoryItemDialog: false,
      historyItemTab: "tab-details",
      dialogHeightHistoryItem: "30vh",
      fleetHistoryItemRecord: {},

      // non availability properties
      showNonAvailForm: false,
      nonAvailTab: "tab-details",
      dialogHeightNonAvail: "40vh",

      nonAvailRecord: {},
      nonAvailList: [],
      nonAvailFormErrors: [],

      nonAvailTypeList: [],

      // associated driver props:

      showDriverDialog: false,
      selectedDrivers: [],
      driverList: [],
    };
  },
};
</script>

<style></style>
